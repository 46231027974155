import * as moment from 'moment';
import { catchError, tap } from 'rxjs/operators';
import { filter as _filter, get as _get } from 'lodash';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject, Subject } from 'rxjs';
import { Router } from '@angular/router';

import { ApiPostConsent } from '../../interfaces/api-post-consent.interface';
import { ApiPostPayment } from '../../interfaces/api-post-payment.interface';
import { ApiPostRecipient, Attachment } from '../../interfaces/api-post-recipient.interface';
import { ApiPostStudent } from '../../interfaces/api-post-student.interface';
import { ApiResponseOrder } from '../../interfaces/api-response-order.interface';
import { ApiResponseRecipientDetails } from '../../interfaces/api-response-recipient-profile.interface';
import { CheckApiResponse } from '../../classes/check-api-response';
import { CommonValues } from '../../classes/common-values';
import { DataService } from '../../services/data/data.service';
import { SecurityService } from '../../services/security/security.service';

@Injectable()
export class PostService {
  defaultState = 'International';
  defaultZip = this.commonValues.defaultZip;
  organizationNetworkType$ = new BehaviorSubject(<Number> null);
  unsubscribe$ = new Subject();

  constructor(
    private checkApiResponse: CheckApiResponse,
    private commonValues: CommonValues,
    private dataService: DataService,
    private http: HttpClient,
    private router: Router,
    private securityService: SecurityService
  ) { }


  saveResponse(json: ApiResponseOrder): void {
    if (this.checkApiResponse.isValid(json)) {
      this.dataService.save({
        response: json
      });
    } else {
      throw new Error('Error found in valid response JSON');
    }
  }

  saveToken(token: string): void {
    if (token) {
      this.dataService.save({
        jsonWebToken: token
      });
    } else {
      throw new Error('Missing JWT value');
    }
  }

  student(): Observable<HttpResponse<ApiResponseOrder>> {
    const data = this.dataService.get();
    const requestor = data.form.requestor;
    const dateOfBirth = moment(requestor.personal.dob, this.commonValues.dateFormat.input).format(
      this.commonValues.dateFormat.output
    );
    const attendSchoolInfoData = requestor.attend.programs
      ? requestor.attend.programs.map(program => {
        return {
          attendSchool: program.program ? this.commonValues.api.yes : null,
          attendSchlBeg: program.yearFrom || null,
          attendSchlEnd: program.yearTo || null
        };
      })
      : null;
    const postData: ApiPostStudent = {
      orderHeader: {
        archiveStudent: requestor.personal.enrolledBefore || this.commonValues.api.no,
        feePathTypeAnswer: requestor.personal.path,
        integrationHoldActivityId: _get(data.studentProfile, 'integrationHoldActivityId', null),
        toScprofilId: this.dataService.get().schoolProfile.toScprofilId,
        referMethod: this.getReferMethod(),
        feePathType: (this.dataService.postAuthorizationData &&
          this.dataService.postAuthorizationData.feepathType === this.commonValues.api.free) ? this.commonValues.api.free : (this.dataService.postAuthorizationData &&
            this.dataService.postAuthorizationData.feepathType === this.commonValues.api.pay) ? this.commonValues.api.pay : null,
      },
      student: {
        attendBeginYear: requestor.personal.enrolledYearFrom,
        toScCampusId: requestor.personal.campus,
        toScCampusName: null,
        attendSchoolInfo: attendSchoolInfoData,
        attendEndYear: requestor.personal.enrolledYearTo,
        city: requestor.contact.city,
        country: requestor.contact.country,
        currEnrolled: requestor.personal.enrolledCurrently,
        zeroFeePrgmCurrEnroll: requestor.personal.zeroFeePrgmCurrEnroll,
        dateOfBirth: dateOfBirth,
        degreeInfo: [
          {
            degreeTitle: requestor.attend.degree1,
            awardYear: requestor.attend.year1
          },
          {
            degreeTitle: requestor.attend.degree2,
            awardYear: requestor.attend.year2
          },
          {
            degreeTitle: requestor.attend.degree3,
            awardYear: requestor.attend.year3
          },
          {
            degreeTitle: requestor.attend.degree4,
            awardYear: requestor.attend.year4
          }
        ],
        email: requestor.contact.emailPrimary,
        firstName: requestor.personal.nameFirst,
        lastName: requestor.personal.nameLast,
        middleName: requestor.personal.nameMiddle,
        phone: requestor.contact.phone,
        schlFirstName: requestor.personal.nameChangedFirst,
        schlLastName: requestor.personal.nameChangedLast,
        schlMiddleName: requestor.personal.nameChangedMiddle,
        livedFirstName: requestor.personal.livedNameFirst,
        livedLastName: requestor.personal.livedNameLast,
        livedMiddleName: requestor.personal.livedNameMiddle,
        allowStudentName: requestor.personal.allowStudentName && requestor.personal.allowStudentName ==='Y' ? this.dataService.get().schoolProfile.allowStudentName : null,
        ssn: (requestor.personal.ssnPrimary ? requestor.personal.ssnPrimary.replace(/-/g, '') : null)
          || ((this.dataService.postAuthorizationData
            && (this.dataService.postAuthorizationData.appType === this.commonValues.applicationType.studentSelfService || this.dataService.postAuthorizationData.appType === this.commonValues.applicationType.saml)
            && this.dataService.postAuthorizationData.ssn) ? this.dataService.postAuthorizationData.ssn : null),
        state: requestor.contact.state || this.defaultState,
        street1: requestor.contact.addressLine1,
        street2: requestor.contact.addressLine2,
        studentId: (requestor.personal.studentIdPrimary? requestor.personal.studentIdPrimary.replace(/-/g, '') : null)
         ||  ((this.dataService.postAuthorizationData
            && (this.dataService.postAuthorizationData.appType === this.commonValues.applicationType.studentSelfService || this.dataService.postAuthorizationData.appType === this.commonValues.applicationType.saml || this.dataService.postAuthorizationData.appType === this.commonValues.applicationType.myHub)
            && this.dataService.postAuthorizationData.collegeStudentId) ? this.dataService.postAuthorizationData.collegeStudentId : null),
        textMessging: {
          mobilePhoneNumber:
            requestor.contact.textUpdates === this.commonValues.api.yesSMS
              ? (requestor.contact.phone.replace(/[-]+/g, ''))
              : null,
          sendTxtMsg: requestor.contact.textUpdates
        },
        updateSchoolRec: requestor.contact.updateRecords,
        verifiedStudentId: _get(data.studentProfile, 'verifiedStudentId', null),
        zip: requestor.contact.zip || this.defaultZip
      }
    };

    // cf. https://angular.io/guide/http#reading-the-full-response
    // need the full response on this request so we can access the headers, and save the JWT value;
    // the JWT value will be used on subsequent API calls;
    return this.http.post<ApiResponseOrder>('/api/tsorder/order', postData, { observe: 'response' }).pipe(
      tap(resp => {
        this.saveToken(resp.headers.get('authorization-token'));
        this.saveResponse(resp.body);
      }),
      catchError(error => this.securityService.catchResponse(error))
    );
  }

  getReferMethod() {
    const postAuthorizationData = this.dataService.postAuthorizationData;
    let referMethod = null;
    if (postAuthorizationData && postAuthorizationData.appType) {
      switch (postAuthorizationData.appType) {
        case this.commonValues.applicationType.studentSelfService:
          referMethod = 'S';
          break;
        case this.commonValues.applicationType.myHub:
          referMethod = 'H';
          break;
        case this.commonValues.applicationType.saml:
          referMethod = 'O';
            break;
        default:
          break;
      }
    }
    return referMethod;
  }



  getAttachments(recipient): Attachment[] {
    const uploadedFiles: Attachment[] = [];
    Array.from(recipient.attachments).forEach((uploadedFile: any, index: number) => {
      if (uploadedFile.attachmentResponse && uploadedFile.attachmentResponse.status === 'success') {
        const tempAttachment = {
          fileIdentifier: uploadedFile.attachmentResponse.fileIdentifier,
          originalFileName: uploadedFile.attachmentResponse.originalFileName,
          contentType: uploadedFile.attachmentResponse.contentType
        };
        uploadedFiles.push(tempAttachment);
      }
    });
    return (uploadedFiles.length > 0) ? uploadedFiles : null;
  }

  getRecipientEmail(recipient): string {
    if (recipient.who.sendElectronically === this.commonValues.api.yes) {
      // set recipient email to the one entered on the recipient/select page if the user answered yes to send electronically
      // regardless if there was a match or not, this allows us to reuse the email and avoid going to delivery info page
      return recipient.who.emailPrimary;
    }
    return recipient.address.emailPrimary;
  }

  recipients(): Observable<ApiResponseOrder> {
    const data = this.dataService.get();
    const orderId = this.dataService.get().response.orderHeader.toOrderId;
    const formRecipients = this.dataService.get().form.recipients;
    const postData: ApiPostRecipient[] = [];
    const mailDeliveryMethods = [
      this.commonValues.api.mail,
      this.commonValues.api.faxExpress,
      this.commonValues.api.overnight,
      this.commonValues.api.faxMail
    ];
     let isNullsubNetworkFormField;
    // loop through the entered recipients to create the payload to send to the API;
    for (let index = 0; index < formRecipients.length ; index++){
      let deliveryMethod = null;
      const recipient = formRecipients[index];
      if (!this.dataService.isNullorBlank(recipient.delivery.zeroFeeProgramEligible) && recipient.delivery.zeroFeeProgramEligible === 'Y') {
        // if zero fee program is eligible then get deliverymethod, transcript and quantity from zero pay or free options
        deliveryMethod = this.dataService.getZeroFeeDeliveryMethodObject(
          recipient.delivery.deliveryMethod
        );
      } else {
        deliveryMethod = this.dataService.getDeliveryMethodObject(
          recipient.delivery.deliveryMethod
        );
      }

      // determine which value to return for State and ZIP;
      // if the user didn't enter a value, we are to pass the defined value - for certain delivery methods;
      const deliveryMethodType = deliveryMethod.deliveryMethodType;
      const defaultState =
        mailDeliveryMethods.indexOf(deliveryMethodType) > -1 ? this.defaultState : null;
      const defaultZip =
        mailDeliveryMethods.indexOf(deliveryMethodType) > -1 ? this.defaultZip : null;

      // return either the department or subnetwork object (both are the same);
      // otherwise an empty object for _get() to find nothing in;
      const getDepartment = (): ApiResponseRecipientDetails => {
        const departments = _get(recipient.who, 'recipientResponse.recipientDepartments', []);
        const recipientResponseDepartment = _filter(departments, {
          deptId: recipient.who.department
        });

        return recipientResponseDepartment.length ? recipientResponseDepartment[0] : null;
      };
      const getSubNetwork = (): ApiResponseRecipientDetails => {
        return _get(recipient.who, 'recipientResponse.subNetwork', null);
      };
      const getExchangeChanel = (): ApiResponseRecipientDetails => {
        if ((recipient.who.oborDeliveryEligibility !== null && recipient.who.oborDeliveryEligibility === this.commonValues.api.yes) || (recipient.who.recipientResponse && (recipient.who.recipientResponse.exchangeNetworkType === this.commonValues.api.speede || recipient.who.recipientResponse.exchangeNetworkType === this.commonValues.api.fast))) {
          return _get(recipient.who, 'recipientResponse.exchangeChanel', null);
        }
      };
      const getExchangeDataObject = (): ApiResponseRecipientDetails => {
        return getDepartment() || getSubNetwork() || getExchangeChanel();
      };

      const getAllowIndexFile = (): boolean => {
        const senderAllowIndexFile = this.dataService.get().schoolProfile.allowIndexFile;
        const exchangeDataObject = getExchangeDataObject();
        return ((senderAllowIndexFile !== null && senderAllowIndexFile  === this.commonValues.api.yes && 
          exchangeDataObject && exchangeDataObject.sendIndexFile === this.commonValues.api.yes) ||  exchangeDataObject && exchangeDataObject.deptProcessingOption && exchangeDataObject.deptProcessingOption === this.commonValues.api.slate);
      };

      const getDeptProcessingOption = (): string => {
        
        const exchangeDataObject = getExchangeDataObject();
        if(exchangeDataObject && exchangeDataObject.deptProcessingOption && exchangeDataObject.deptProcessingOption === this.commonValues.api.slate){
          return this.commonValues.api.slate;
         }else{
          return _get(getExchangeDataObject(), 'subNetworkType', null);
        }
        
      };

      const getSPDEZeroFeePrgmEligible = (): boolean => {
        const exchangeNetworkType = _get(recipient.who.recipientResponse, 'exchangeNetworkType', null);
        return this.dataService.getSPDEZeroFeeEligibilityByNetworkCode(exchangeNetworkType);
      };
      const getFASTZeroFeePrgmEligible = (): boolean => {
        const exchangeNetworkType = _get(recipient.who.recipientResponse, 'exchangeNetworkType', null);
        return this.dataService.getFASTZeroFeeEligibilityByNetworkCode(exchangeNetworkType);
      };
      
      const exchangeNetworkType = recipient.who.recipientResponse?recipient.who.recipientResponse.exchangeNetworkType:null;
      const orderProcessFeePaidBy = this.dataService.getOrderProcessFeePaidByValue(recipient.delivery.deliveryMethod, exchangeNetworkType);

      // if the recipient isn't defined in the form data model, then the address fields were skipped, and recipient was never set;
      // if that's the case, then get the value here (pass in the index so the getRecipient() knows which array item to reference);
      // this will happen with ETX recipients;
      const attentionField = (recipient.who.recipientResponse && (recipient.who.recipientResponse.exchangeNetworkType === this.commonValues.api.obor || recipient.who.recipientResponse.exchangeNetworkType === this.commonValues.api.speede || recipient.who.recipientResponse.exchangeNetworkType === this.commonValues.api.fast));
      const organization = recipient.address.recipient || this.dataService.getRecipient(index);
      const recipientResponse = (recipient.who.oborDeliveryEligibility !== null && recipient.who.oborDeliveryEligibility === this.commonValues.api.yes) ? null : recipient.who.matchedUboxEntry;
      const deptRecipientId = (recipientResponse ? recipientResponse.etxDeptId : null);
      const isSPDEZeroPrgmEligible = getSPDEZeroFeePrgmEligible();
      const isFASTZeroPrgmEligible = getFASTZeroFeePrgmEligible();
      const recipientItem: ApiPostRecipient = {
        amcasId: recipient.who.aamcAccountNumber,
        amcasTranscriptId: recipient.who.amcasTranscriptIdNumber,
        attachments: this.getAttachments(recipient),
        attention: recipient.address.attention || (attentionField ? null : _get(getExchangeDataObject(), 'deptName', null)),
        city: recipient.address.city,
        country: recipient.address.country,
        degreeTitle: (this.dataService.isSchoolEllucian() === false) ? recipient.delivery.degreeTitle : recipient.delivery.program,
        deliveryMethodId: recipient.delivery.deliveryMethod,
        deptProcessingOption: (recipientResponse && recipientResponse.deptProcessingOption && recipientResponse.deptProcessingOption===this.commonValues.api.slate) ? recipientResponse.deptProcessingOption: getDeptProcessingOption(),
        email: this.getRecipientEmail(recipient),
        etxDeliveryFileFormat: recipientResponse ? recipientResponse.fileFormat : _get(getExchangeDataObject(), 'fileFormat', null),
        etxDeptRecipientId: deptRecipientId ? deptRecipientId : _get(getExchangeDataObject(), 'ftpAccountName', null),
        exchangeNetworkTypeCode: _get(recipient.who.recipientResponse, 'exchangeNetworkType', null),
        etxRgtryUboxId: (recipient.who.matchedUboxEntry) ? recipient.who.matchedUboxEntry.etxRgtryUboxId : _get(recipient.who.recipientResponse, 'etxRgtryUboxId', null),
        sendToSchoolElectronic: (recipient.who.sendElectronically) ? recipient.who.sendElectronically : null,
        ficeCode: recipient.who.recipientFiceCode,
        feeReduceEligible: (isSPDEZeroPrgmEligible || isFASTZeroPrgmEligible) ? recipient.delivery.zeroFeeProgramEligible : null,
        isVerifiedAddress: recipient.address.isVerifiedAddress,
        isAckgInvalidAddress: recipient.address.isAckgInvalidAddress,
        liaisonCasId: recipient.who.casId,
        lsacId: recipient.who.lsacAccountNumber,
        oborDeliveryEligibility: recipient.who.oborDeliveryEligibility ? recipient.who.oborDeliveryEligibility : null,
        organization: organization,
        phone: recipient.address.phone,
        processingOption: recipient.delivery.transcriptWhen,
        quantityId: recipient.delivery.howMany,
        sendToType: recipient.who.recipientType,
        specialInstr: recipient.delivery.specialInstructions,
        state: recipient.address.state || defaultState,
        street1: recipient.address.addressLine1,
        street2: recipient.address.addressLine2,
        term: (this.dataService.isSchoolEllucian() === false) ? recipient.delivery.term : recipient.delivery.ungradedTerm,
        year: recipient.delivery.year,
        courseName1: recipient.delivery.course1,
        courseName2: recipient.delivery.course2,
        toOrderId: orderId,
        xcriptPurposeId: recipient.delivery.transcriptPurpose,
        xcriptTypeId: recipient.delivery.transcriptType,
        zip: recipient.address.zip || defaultZip,
        isZeroFeePrgrmEligible: (!isSPDEZeroPrgmEligible && !isFASTZeroPrgmEligible  && recipient.delivery.zeroFeeProgramEligible) ? recipient.delivery.zeroFeeProgramEligible : null,
        zeroFeePrgmId: (recipient.delivery.zeroFeeProgramEligible === 'Y' && recipient.who.recipientResponse.zeroFeePrgmId) ? recipient.who.recipientResponse.zeroFeePrgmId : null,
        enteredAddressLine1: recipient.address && recipient.address.isAckgInvalidAddress !== 'Y'?recipient.address.enteredAddressLine1:null,
        enteredAddressLine2: recipient.address && recipient.address.isAckgInvalidAddress !== 'Y'?recipient.address.enteredAddressLine2:null,
        recievingFileFormat: recipientResponse ? recipientResponse.recievingFileFormat : _get(getExchangeDataObject(), 'recievingFileFormat', null),
        allowDataConvert: ((recipientResponse && recipientResponse.recievingFileFormat)? 'Y': null) || (_get(getExchangeDataObject(), 'recievingFileFormat', null)? 'Y' : null),
        orderProcessFeePaidBy: orderProcessFeePaidBy,
        consentReleaseStudentInfo: recipient.delivery.consentShareInfo,
        allowIndexFile: getAllowIndexFile() ? this.commonValues.api.yes : this.commonValues.api.no,
        allowDocWaived: recipient.delivery.allowDocWaived == null ? null : (recipient.delivery.allowDocWaived === this.commonValues.api.yes ? this.commonValues.api.no : this.commonValues.api.yes)
      };
      // for LSAC, AMCAS, LIASON org  we need to verify that ID's are present otherwise stop the processs and request to enter again.
      isNullsubNetworkFormField = this.verifyNotNullOrganizationId(recipient.who);
     if (isNullsubNetworkFormField){
      this.organizationNetworkType$.next(index);
      break;
     }
      postData.push(recipientItem);
    }
    // if organizationId is required and is null then stop the process and show error popup
    if (isNullsubNetworkFormField){
       return of(null);
    }
    else{
    const orderId = this.dataService.get().response.orderHeader.toOrderId;
    return this.http.post<ApiResponseOrder>(`/api/tsorder/order/${orderId}/recipients`, postData).pipe(
      tap(json => this.saveResponse(json)),
      catchError(error => this.securityService.catchResponse(error))
    );
  }
  }

  verifyNotNullOrganizationId(item){
    const recipient = item.recipientResponse && item.recipientResponse.subNetwork && item.recipientResponse.subNetwork.subNetworkType;
      const subNetworkType = recipient ? item.recipientResponse.subNetwork.subNetworkType : null;
       if (subNetworkType && (subNetworkType === this.commonValues.api.amcas || subNetworkType === this.commonValues.api.lsac || subNetworkType === this.commonValues.api.liason)) {
          // if the value is null then needs to redirect to recipient Select page
          let recipientValue = null;
         switch (subNetworkType) {
           case this.commonValues.api.amcas:
              recipientValue = item.aamcAccountNumber;
             break;
           case this.commonValues.api.lsac:
              recipientValue = item.lsacAccountNumber;

             break;
             case this.commonValues.api.liason:
                recipientValue = item.casId;
             break;
         }
           if (!recipientValue){
          return true;
         }
       }
       return null;
  }


  payment(): Observable<ApiResponseOrder> {
    const orderId = this.dataService.get().response.orderHeader.toOrderId;
    const payment = this.dataService.get().form.payment;
    const postData: ApiPostPayment = {
      billCity: payment.city,
      billCountry: payment.country,
      billPhone: null,
      billState: payment.state,
      billStreet1: payment.addressLine1,
      billStreet2: payment.addressLine2,
      billZip: payment.zip,
      ccAmount: this.dataService.get().response.orderHeader.totalFee,
      ccExpiryMonth: payment.expirationMonth,
      ccExpiryYear: `20${payment.expirationYear}`,
      ccName: payment.name,
      ccType: payment.ccType,
      payeezyToken: payment.payeezyToken
    };

    return this.http.post<ApiResponseOrder>(`/api/tsorder/order/${orderId}/payment`, postData).pipe(
      tap(json => this.saveResponse(json)),
      // catchError(error => this.securityService.catchResponse(error))
    );
  }

  consent(): Observable<ApiResponseOrder> {
    const orderId = this.dataService.get().response.orderHeader.toOrderId;
    const postData: ApiPostConsent = {
      lines: this.dataService.get().form.consent.signature.lines, 
      legalSignatureName: this.dataService.get().form.consent.legalSignatureName
    };
    return this.http.post<ApiResponseOrder>(`/api/tsorder/order/${orderId}/consent-signature`, postData).pipe(
      tap(json => this.saveResponse(json))
      // handle the error at component level
      // catchError(error => this.securityService.catchResponse(error))
    );
  }
}
